import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

import "./normalize.css"

import MiniHeader from "../components/miniheader/miniheader"
import Divider from "../components/divider/divider"
import Menu from "../components/menu/menu"
import Bloglist from "../components/bloglist/bloglist"
import Footer from "../components/footer/footer"
import Tancirna from "../components/tancirna/tancirna"
import Contact from "../components/contact/contact"

const Nedelnitancirna = () => {
	return (

		<Layout>
			<Seo title="Nedělní tančírna pro veřejnost" description="Přijďte si zatančit společenské tance, procvičit si, co jste se naučili v tanečních" />

			<MiniHeader text='tancirna'></MiniHeader>
			<Tancirna></Tancirna>
			<Contact></Contact>
			<Bloglist></Bloglist>
			<Divider></Divider>
			<Menu></Menu>
			<Footer></Footer>

		</Layout>

	)
}

export default Nedelnitancirna