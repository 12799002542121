import React from "react"

import "../course/course.scss"
import "../../pages/index.scss"

const Tancirna = () => (

	<div className="tz-course">

		<div className="container mb3">

			<span className="mt1 warning" data-sal="fade" data-sal-duration="1000">open class dle dohody - registrace nutná</span>
			<h2 className="mt1 mb3" data-sal="fade" data-sal-duration="1000">Přijďte si zatančit společenské tance, procvičit si, co jste se naučili v&nbsp;tanečních</h2>

			<div className="container-50">
				<div className="tz-course__list" data-sal="fade" data-sal-duration="1000">
					<div className="tz-course__list-item">
						<div>
							<div className="tz-course__header">neděle (dle dohody)</div>
							<div className="tz-course__content">19:15 - 20:45</div>
							<strong>předchozí registrace nutná</strong>
						</div>
					</div>
					<div className="tz-course__list-item">
						<div>
							<div className="tz-course__header">cena/osobu:</div>
							<div className="tz-course__content">200,-</div>
						</div>
					</div>
				</div>
				<div data-sal="fade" data-sal-duration="1000">
					<div>
						<h3>Přihlašte se:</h3>
						<ul className="tz-list">
							<li className="tz-list__item">✅ na emailu <a className="tz-list__link" href="mailto:tanecni.zlonin@gmail.com">tanecni.zlonin@gmail.com</a></li>
							<li className="tz-list__item">✅ na messengeru stránky <a className="tz-list__link" href="https://www.facebook.com/tanecni.zlonin" target="_blank" rel="noreferrer">Taneční Zlonín</a></li>
							<li className="tz-list__item">✅ na telefonu/WhatsApp <a className="tz-list__link" href="tel:+420732560838">+420 732 560 838</a></li>
						</ul>
					</div>
				</div>
			</div>

		</div>

		<div className="container mt5">

			<div>
				<p data-sal="fade" data-sal-duration="1000">Zveme Vás na občasnou nedělní tančírnu od&nbsp;19:15 ve&nbsp;společenském sále KC&nbsp;Zlonín, kde bude ideální příležitost procvičit vše, co jste se naučili v&nbsp;našem nebo jiném tanečním kurzu.</p>
				<p data-sal="fade" data-sal-duration="1000">To vše na reprodukovanou hudbu, pod&nbsp;vedením lektora Taneční&nbsp;Zlonín.</p>
				<h3 data-sal="fade" data-sal-duration="1000">V případě zájmu o&nbsp;účast na&nbsp;nadělní tančírně se nám ozvěte, budeme Vás informovat o&nbsp;nejbližším termínu.</h3>
			</div>

		</div>


		<div className="tz-info">
			<div className="container tz-color-black">
				<h3 className="mt1 mb3 tz-color-black" data-sal="fade" data-sal-duration="1000">Organizační informace k&nbsp;nedělní tančírně</h3>

				<h4 className="tz-color-black" data-sal="fade" data-sal-duration="1000">Otevření sálu a&nbsp;vstup</h4>
				<p data-sal="fade" data-sal-duration="1000">
					Vstup na sál je bočním vchodem. Taneční sál bude otevřen již od&nbsp;cca&nbsp;19:00 a&nbsp;bude navazovat na&nbsp;lekci Latin Fever.
				</p>

				<h4 className="tz-color-black" data-sal="fade" data-sal-duration="1000">Odkládání a&nbsp;přezouvání</h4>
				<p data-sal="fade" data-sal-duration="1000">
					Všechny své věci si s&nbsp;sebou noste na&nbsp;sál, kde jsou k&nbsp;dispozici židle a&nbsp;věšáky na&nbsp;odložení. Do&nbsp;čisté taneční obuvi se prosím přezouvejte ihned po&nbsp;vstupu na&nbsp;taneční sál, abychom si ho udrželi během lekce čistý a&nbsp;zajistili jeho bezvadný stav.
				</p>

				<h4 className="tz-color-black" data-sal="fade" data-sal-duration="1000">Oblečení a&nbsp;obuv</h4>
				<p data-sal="fade" data-sal-duration="1000">
					Vezměte si pohodlné oblečení, ve&nbsp;kterém se budete cítit příjemně a&nbsp;pohodlně. Obuv: pohodlná společenská nebo přímo taneční. Vyvarujte se kovových podpatků, které by mohly poškrábat parket a&nbsp;pánové černých podrážek, které by barvily parket. Nemělo by se jednat o&nbsp;obuv, ve&nbsp;které jste přišli z&nbsp;venku.
				</p>

				<h4 className="tz-color-black" data-sal="fade" data-sal-duration="1000">Občerstvení a&nbsp;restaurace</h4>
				<p data-sal="fade" data-sal-duration="1000">
					S sebou na&nbsp;taneční lekci si přineste své vlastní občerstvení a&nbsp;pití. Během nedělní tančírny je restaurace Zlonínský Dvůr uzavřena, pro&nbsp;návštěvu toalet prosíme používejte venkovní vchod.
				</p>

				<h4 className="tz-color-black" data-sal="fade" data-sal-duration="1000">Těšíme se na&nbsp;vás</h4>
				<p data-sal="fade" data-sal-duration="1000">
					Přineste si dobrou náladu a&nbsp;v&nbsp;případě jakýchkoliv dotazů nás neváhejte kontaktovat.
				</p>

			</div>
		</div>

	</div>

)

export default Tancirna